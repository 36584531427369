import React from "react";
import tk from "../TK.svg";

function Nav() {
	return (
		<div>
			<nav className="lg:top-20 lg:right-20 top-4 right-4 fixed">
				<img className="h-8 w-8" src={tk} alt="Workflow" />
			</nav>
		</div>
	);
}
export default Nav;
