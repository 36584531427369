import React from "react";
import Typewriter from "typewriter-effect";

function Hero() {
	return (
		<div className="bg-primary min-h-screen flex flex-col justify-center items-center lg:text-6xl md:text-5xl sm:text-4xl text-3xl font-black mb-0 text-secondary">
			<Typewriter
				onInit={(typewriter) => {
					typewriter
						.changeDelay(75)
						.typeString("Hi, I'm Tom. A human from the UK!")
						.pauseFor(2000)
						.changeDeleteSpeed(3)
						.deleteChars(20)
						.typeString("A coder from the UK!")
						.pauseFor(2000)
						.changeDeleteSpeed(3)
						.deleteChars(20)
						.typeString("A Full Stack Developer from the UK!")
						.pauseFor(2000)
						.start();
				}}
			/>
		</div>
	);
}

export default Hero;
