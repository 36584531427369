import React from "react";
import ImageGrid from "./ImageGrid";

function TechStack() {
	return (
		<div className="min-h-screen text-secondary bg-primary text-center">
			<h2 className="lg:text-4xl md:text-3xl sm:text-2xl text-xl font-black md:p-10 p-5">
				Some of the tech I work with...
			</h2>
			<ImageGrid />
		</div>
	);
}

export default TechStack;
