import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import TechStack from "./components/TechStack";

function Routes() {
	return (
		<Router>
			<Route path="/" exact>
				<Hero />
				<About />
				<TechStack />
				<Contact />
			</Route>
		</Router>
	);
}

export default Routes;
