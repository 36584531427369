import React from "react";
import javascript from "../javascript.svg";
import nodejs from "../nodejs.svg";
import html from "../html.svg";
import css3 from "../css3.svg";
import reactimg from "../react.svg";
import postgresql from "../postgresql.svg";
import express from "../express.svg";
import bootstrap from "../bootstrap.svg";
import git from "../git.svg";
import jest from "../jest.svg";

function ImageGrid() {
	return (
		<div className="gap-10 p-10 grid sm:grid-cols-3 lg:grid-cols-5 grid-cols-2 lg:text-3xl xl:pl-40 xl:pr-40 md:text-2xl sm:text-xl text-l place-items-center">
			<div>
				<img
					className="pb-5"
					min-width="100%"
					src={javascript}
					alt="javascript logo"
				/>
				<p>Javascript</p>
			</div>
			<div>
				<img className="pb-5" min-width="100%" src={nodejs} alt="nodejs logo" />
				<p>NodeJS</p>
			</div>
			<div>
				<img className="pb-5" min-width="100%" src={html} alt="html5 logo" />
				<p>HTML5</p>
			</div>
			<div>
				<img className="pb-5" min-width="100%" src={css3} alt="css3 logo" />
				<p>CSS3</p>
			</div>
			<div>
				<img
					className="pb-5"
					min-width="100%"
					src={reactimg}
					alt="react logo"
				/>
				<p>React</p>
			</div>
			<div>
				<img
					className="pb-5"
					min-width="100%"
					src={postgresql}
					alt="postgresql logo"
				/>
				<p>PostgreSQL</p>
			</div>
			<div>
				<img
					className="pb-5"
					min-width="100%"
					src={express}
					alt="express logo"
				/>
				<p>Express</p>
			</div>
			<div>
				<img className="pb-5" min-width="100%" src={git} alt="git logo" />
				<p>Git</p>
			</div>
			<div>
				<img className="pb-5" min-width="100%" src={jest} alt="jest logo" />
				<p>Jest</p>
			</div>
			<div className="sm:hidden lg:block">
				<img
					className="pb-5"
					min-width="100%"
					src={bootstrap}
					alt="bootstrap logo"
				/>
				<p>Bootstrap</p>
			</div>
		</div>
	);
}

export default ImageGrid;
