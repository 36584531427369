import React from "react";
import email from "../email.svg";
import linkedin from "../linkedin.svg";

function Contact() {
	return (
		<div className="min-h-screen text-secondary bg-primary text-center mb-0">
			<p className="lg:text-6xl md:text-5xl sm:text-4xl text-3xl font-black p-10">
				Want to know more? Great!
			</p>
			<p className="lg:text-3xl xl:pl-40 xl:pr-40 md:text-2xl sm:text-xl pl-10 pr-10 text-l pb-10">
				I'm always looking to connect with companies and individuals whether
				it's to discuss opportunities or just say hello.
				<br />
				<br />
				Whatever the case may be, please feel free to reach out to me at either
				of the below platforms:
			</p>
			<div className="gap-10 p-10 grid grid-cols-2 lg:text-3xl xl:pl-40 xl:pr-40 md:text-2xl sm:text-xl text-l place-items-center">
				<div>
					<a href={"mailto:thomas.kirk1989@gmail.com"}>
						<img min-width="100%" src={email} alt="email logo" />
						<p>Email</p>
					</a>
				</div>
				<div>
					<a href={"https://www.linkedin.com/in/thomaskirkdev/"}>
						<img min-width="100%" src={linkedin} alt="linkedin logo" />
						<p>Linkedin</p>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Contact;
