import React from "react";
import PersonalPicture from "./PersonalPicture";

function About() {
	return (
		<div className="min-h-screen md:flex text-secondary bg-primary text-center justify-center items-center">
			<div>
				<PersonalPicture />
			</div>
			<div className="mb-0">
				<h2 className="lg:text-4xl md:text-3xl md:pt-0 sm:text-2xl text-xl font-black p-10">
					About me
				</h2>
				<p className="lg:text-3xl xl:pl-40 xl:pr-40 md:text-2xl sm:text-xl pl-10 pr-10 text-l pb-10">
					I began my development career when attending a Northcoders Full Stack
					Developer Bootcamp in March 2021.
					<br />
					<br />I have had the pleasure of working with other talented aspiring
					developers and have produced projects like
					<a
						href="https://github.com/tomkirky/cleaner-project"
						className="text-detail hover:text-secondary"
					>
						{" "}
						Cleanr
					</a>
					.
					<br />
					<br />
					I'm alway's looking to improve and develop new skills in my ever
					growing tech stack.
				</p>
			</div>
		</div>
	);
}

export default About;
